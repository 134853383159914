<template>
	<div class="modal-bg" v-show="show" @mousemove="modalMove" @mouseup="cancelMove">
        <div class="modal-container">
            <!-- <div class="modal-header" @mousedown="setStartingPoint">
                {{ title }}
            </div> -->
            <div class="modal-main">
                 <div class="closeDiv">
                    <img class="closeImg" @click="closeDiv" src="../assets/close.png">        
                </div>    
                <slot></slot>  
                <div class="qrcodeDiv">
                    <img class="qrcode" src="../assets/qrcode.png">        
                </div>                   
            </div>             
            <!-- <div class="modal-footer">
                <el-button round @click="hideModal">取消</el-button>
                <el-button type="primary" class="submitButton" round @click="submit">确认</el-button>
            </div>                      -->
        </div>
       
    </div>
</template>
<script>
export default {
    name: 'modal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            x: 0,
            y: 0,
            node: null,
            isCanMove: false
        }
    },
    mounted() {
        this.node = document.querySelector('.modal-container')
    },
    methods: {
        hideModal() {
            this.$emit('hideModal')
        },

        submit() {
            this.$emit('submit')
        },
        closeDiv(){
            this.$emit('closeDiv')
        },
        setStartingPoint(e) {
            this.x = e.clientX - this.node.offsetLeft
            this.y = e.clientY - this.node.offsetTop
            this.isCanMove = true
        },

        modalMove(e) {
            if (this.isCanMove) {
                this.node.style.left = e.clientX - this.x + 'px'
			    this.node.style.top = e.clientY - this.y + 'px'
            } 
        },

        cancelMove() {
            this.isCanMove = false
        }
    }
}
</script>

<style scoped>
.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 10;
}
.modal-container {
    /* background: #fff; */
    border-radius: 10px;
    overflow: hidden;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.modal-header {
    height: 40px;
    background: #25C8CC;
    /* background: #409EFF; */
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
}
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 57px;
    border-top: 1px solid #ddd;
}
.modal-footer button {
    width: 100px;
}
.modal-main {
    padding: 15px 65px;
}
.submitButton{
     background: #25C8CC;
     border: 0;
}
.qrcodeDiv{
    width: 450px;
    margin-top: 50px;
    height: 400px;
}
.qrcode{
    display: block;
    margin: 0 auto;
    width: 275px;
}

.closeDiv{
   position: relative;
    height: 80px;
    width: 500px;
}
.closeImg{
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
}
</style>